/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/FBEfKqBKPcn
 */
// import Link from "next/link"
import { Button } from "@/components/ui/button"
import { SheetTrigger, SheetContent, Sheet } from "@/components/ui/sheet"

// import "@/styles/globals.css"
// import "../styles/globals.css"
import type { ClassNameValue } from "tailwind-merge";
import Logo from "./Logo";

type NavigationItem = {
  name: string;
  href: string;
  current: boolean;
};

type NavigationData = {
  navigation: NavigationItem[];
};

export default function MenuBar(navigation: NavigationData) {
  let navigationData = navigation.navigation

  return (
    <>
      <Sheet>
        <div className="flex items-center justify-between px-6 py-4 bg-white dark:bg-gray-800 shadow-md">
          <a href="/" className="mr-4">
            {/* <svg
              className=" h-6 w-6 text-gray-800 dark:text-white"
              fill="none"
              height="24"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m8 3 4 8 5-5 5 15H2L8 3z" />
            </svg> */}
            <Logo className="h-12 w-12 m-1 text-gray-800 dark:text-white" />
          </a>
          <nav className="hidden md:flex space-x-8 transition-transform">
            {navigationData.map((link) => {
              // const baseClass = "text-gray-600 dark:text-white hover:text-indigo-500 dark:hover:text-indigo-300 transition-transform";
              const baseClass = "text-gray-600 dark:text-white hover:text-primary dark:hover:text-indigo-300 transition-transform";
              // const activeClass = "text-indigo-500 border-b-2 border-indigo-500";
              const activeClass = "border-b-2 border-primary";
              const className = link.current ? `${baseClass} ${activeClass}` : baseClass;

              return (
                <a
                  className={className}
                  // if active text-indigo-500 border-b-2 border-indigo-500
                  // else text-gray-600 dark:text-white hover:text-indigo-500 dark:hover:text-indigo-300 border-b-2 border-transparent hover:border-indigo-500
                  href={link.href}
                  key={Math.random() * 100}
                >
                  {link.name}
                </a>
              )
            })}
          </nav>
          {/* ! Voting (voting live below and then disabled underneath) */}
          {/* <a href="/vote">
            <Button className="hover:bg-primary bg-white text-primary hover:text-white outline outine-1 hover:outline-0 transition-all">
              Vote Now
            </Button>
          </a> */}
          <Button disabled className="hover:bg-primary bg-white text-primary hover:text-white outline outine-1 hover:outline-0 transition-all">
            Voting has now closed
          </Button>
          <div className="md:hidden flex items-center ml-4">
            {/* <button type="button">
              <svg
                className=" h-6 w-6 text-gray-800 dark:text-white"
                fill="none"
                height="24"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="4" x2="20" y1="12" y2="12" />
                <line x1="4" x2="20" y1="6" y2="6" />
                <line x1="4" x2="20" y1="18" y2="18" />
              </svg>
            </button> */}
            <SheetTrigger asChild>
              {/* <Button className="md:hidden shadow-md ml-4" size="icon" variant="outline"> */}
              <Button className="md:hidden ml-4" size="icon" variant="ghost">
                <svg
                  className=" h-6 w-6 text-gray-800 dark:text-white"
                  fill="none"
                  height="24"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="4" x2="20" y1="12" y2="12" />
                  <line x1="4" x2="20" y1="6" y2="6" />
                  <line x1="4" x2="20" y1="18" y2="18" />
                </svg>
              </Button>
            </SheetTrigger>
          </div>
        </div>
        <SheetContent className="bg-white dark:bg-gray-800 shadow-md p-8" side="right">
          <nav className="grid gap-4">
            {navigationData.map((link) => (
              <a
                className="text-gray-600 dark:text-white hover:text-indigo-500 dark:hover:text-indigo-300 border-b-2 border-transparent hover:border-indigo-500"
                href={link.href}
                key={Math.random() * 100}
              >
                {link.name}
              </a>
            ))}
            {/* ! Voting (voting live below and then disabled underneath) */}
            {/* <a href="/vote">
              <Button className="mt-8 border-transparent focus:border-inherit" variant="default">
                Vote Now
              </Button>
            </a> */}
            <Button disabled className="hover:bg-primary bg-white text-primary hover:text-white outline outine-1 hover:outline-0 transition-all">
              Voting has now closed
            </Button>
          </nav>
        </SheetContent>
      </Sheet>
    </>
  )
}
